@mixin table-theme($theme)
{
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  .mat-mdc-header-cell {
    font-size: 12px;
    line-height: 20px !important;
    padding: unset !important;
  }
  .mdc-data-table__cell {
    padding: unset !important;
  }
}
