@use '@angular/material' as mat;

@use './app/styles/colors' as colors;
@use './app/styles/fonts' as fonts;
@use './app/styles/layout' as layout;
@use './app/styles/theme' as theme;
@use './app/styles/mixins' as mixins;

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();
@include mat.all-component-themes(theme.$frontend-theme);


//mixins
@import './app/styles/forms';

@include mixins.button-theme(theme.$frontend-theme);
@include mixins.checkbox-theme(theme.$frontend-theme);
@include mixins.ckeditor-theme(theme.$frontend-theme);
@include mixins.dialog-theme(theme.$frontend-theme);
@include mixins.form-field-theme(theme.$frontend-theme);
@include mixins.form-theme(theme.$frontend-theme);
@include mixins.page-header-theme(theme.$frontend-theme);
@include mixins.radio-button-theme(theme.$frontend-theme);
@include mixins.select-theme(theme.$frontend-theme);
@include mixins.slider-theme(theme.$frontend-theme);
@include mixins.table-theme(theme.$frontend-theme);
@include mixins.toast-theme(theme.$frontend-theme);

// density mixins
@include mat.form-field-density(-3);

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: fonts.$bodyFont, "Helvetica Neue", sans-serif;
  height: 100%;
}

.container {
  padding-top: 75px !important;
  background-color: colors.$whiteColor;
}

fc-progress-bar + .container {
  padding-top: 125px !important;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: mat.get-color-from-palette(theme.$accent, 50);
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: mat.get-color-from-palette(theme.$accent, 50);
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: mat.get-color-from-palette(theme.$accent, 50);
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: mat.get-color-from-palette(theme.$accent, 50);
}

.mapboxgl-popup-content {
  background-color: mat.get-color-from-palette(theme.$accent, 50);
}

.map-popup {
  color: colors.$medDarkGrayColor;
  font-family: fonts.$bodyFont;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.005em;
  line-height: 20px;
  margin-block-end: 0;
  margin-block-start: 0;
}

.popup-disclaimer {
  font-size: 10px;
  letter-spacing: 1px;
  line-height: 14.5px;
  margin-bottom: 0;
  max-width: 350px;
}

.popup-title {
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  margin-block-end: 0.75em;
  margin-block-start: 0.75em;
  white-space: nowrap;
}

.popup-border {
  border: 0.5px solid colors.$medDarkGrayColor;
  margin-bottom: 5px;
}

.popup-details {
  font-size: 14px;
}

.popup-button {
  background: colors.$primary-color-default;
  border-radius: 3px;
  border: 1px solid colors.$primary-color-default;
  box-sizing: border-box;
  color: colors.$whiteColor;
  font-size: 14px;
  height: 34px;
  letter-spacing: 1.45833px;
  margin-top: 0.75em;
  text-align: center;
  width: 100%;
  cursor: pointer;

  &.remove-stand {
    background: colors.$warn-color-default;
    border-color: colors.$warn-color-default;
  }
}

.button-div {
  display: flex;
  column-gap: 5px;

  .remove-stand {
    flex: 1
  }

  .add-stand {
    flex: 3;
  }
}

.mapboxgl-ctrl-top-left {
  margin-top: 60px;
}

.mapboxgl-ctrl-top-right {
  margin-top: 60px;
}

// Globally hide the trash draw tool. Want to keep the hotkey logic, but not show it
.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_trash {
  display: none;
}


input.ng-dirty.ng-invalid {
  border: none;
}

.suggestions-wrapper {
  position: relative;
  top: -5px;
}

// Disable map controls when rest of map is disabled on locate-property paths
.map-disabled > fc-locate-map > #map > div.mapboxgl-control-container > div.mapboxgl-ctrl-top-right > div.mapboxgl-ctrl.mapboxgl-ctrl-group {
  pointer-events: none;
}

// TODO: Figure out a better more specific style for badges used at the top of the screen
// e.g. The toolbar.
.mat-badge-content {
  transform: translate(80px, 12px) !important;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after.drop-down-mat .mat-badge-content {
  right: unset !important;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after.admin-manage-users-badge.drop-down-mat .mat-badge-content {
  right: 80px !important;
}

@-moz-document url-prefix() {
  .mat-badge-content {
    transform: translate(80px, 25px) !important;
  }
}

/* Override for home page select acreage panel */
.cdk-overlay-container .cdk-overlay-pane .acreagePanel {
  max-height: none;
}


.search-bar .mapboxgl-ctrl-geocoder {
  border-radius: 32px;
  border: 1px solid colors.$lightGrayColor;
  font-family: fonts.$bodyFont;
  font-size: 14px;
  width: 100%;
}

@media (max-width: layout.$TABLET-PORTRAIT-MAX-WIDTH) {
  fc-site-detail-information {
    > .mat-expansion-panel {
      > .mat-expansion-panel-content {
        > .mat-expansion-panel-body {
          padding: unset;
        }
      }
    }
  }
}

fc-site-detail-payments > .mat-expansion-panel > .mat-expansion-panel-content > .mat-expansion-panel-body {
  padding-bottom: 33px;
  padding-left: 48px;
}

fc-locate-property-page > .container > mat-drawer-container,
fc-map-layers-page > .container > mat-drawer-container,
fc-map-layers-page-new > .container > mat-drawer-container {
  overflow: unset !important;

  &.closed {
    min-width: 25px;
  }

  .mat-drawer {
    overflow-y: unset;
    right: 25px;
    visibility: visible !important;
  }

  .mat-drawer-opened {
    right: 0;
  }

}

fc-locate-map > .match-parent > .mapboxgl-control-container > .mapboxgl-ctrl-top-left {
  margin-top: 85px;

  .mapboxgl-ctrl {
    margin-left: 34px;
  }
}

.static-five > fc-new-site-step-one {
  display: flex;

  form {
    max-width: 100%;
    width: 540px;
  }
}


// Change all placeholders to match other material inputs
::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.layer-data {
  left: unset;
  right: 10px;
  top: 68px;
  transform: unset !important;

  .mapboxgl-popup-tip {
    display: none;
  }
}

fc-env-ribbon {
  left: -34px;
  position: fixed;
  top: 10px;
  z-index: 9999;

  .env {
    color: colors.$whiteColor;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    height: 42px;
    letter-spacing: 1.25px;
    line-height: 16px;
    padding: 14px;
    text-align: center;
    text-transform: uppercase;
    transform: rotate(-45deg);
    width: 130px;

    &.dev {
      background-color: #1c50d4bd;
    }

    &.stage {
      background: #ed14b0bd;
    }

    &.demo {
      background: #FD852F;
    }
  }
}
