@use '../layout' as layout;

@mixin form-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  form {
    display: flex;
    flex-wrap: wrap;
    column-gap: layout.$flex-gap;
    row-gap: layout.$flex-gap;

    mat-radio-group {
      display: flex;
      gap: layout.$flex-gap;
    }
  }
}
