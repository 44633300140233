@use "../colors" as colors;
@use "../fonts" as fonts;
@use "../layout" as layout;

@mixin dialog-theme($theme)
{
  .cdk-overlay-container {

    .cdk-overlay-pane {
      @media(max-width: layout.$MOBILE-PORTRAIT-MAX-WIDTH) {
        max-width: 100% !important;
      }
    }

    .mat-mdc-dialog-surface {
      position: relative;
      border-radius: 16px;
      padding: 36px;
      width: 556px !important;
      max-width: 90% !important;
      overflow: hidden;
      margin: 10px;

      .mat-mdc-dialog-title {
        padding: 0px;

        &:before {
          content: none;
        }

        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        font-family: fonts.$headerFont;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 1.5px;
        color: colors.$primary-color-default;
        text-align: left;
        padding-bottom: 16px;
      }

      mat-divider {
        color: colors.$darkerLightGrayColor;
        width: 100%;
      }

      .mat-mdc-dialog-content {
        font-family: fonts.$bodyFont;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 1.46px;
        color: colors.$medBlackColor;
        padding: 0;
        padding-top: 16px;
        margin: 0;
        overflow: hidden;
      }

      .mat-mdc-dialog-actions {
        margin-top: 25px;
        display: block;

        .mat-mdc-outlined-button {
          float: left;

          &.mat-warn {
            color: colors.$warn-color-default;
            border-color: colors.$warn-color-default;
          }
        }

        .mat-mdc-raised-button {
          float: right;

          &.mat-warn {
            color: colors.$warn-color-default;
            border-color: colors.$warn-color-default;
          }
        }

        @media(max-width: layout.$MOBILE-PORTRAIT-MAX-WIDTH) {
          display: flex;
          flex-direction: column-reverse;

          button {
            width: 100%;
          }
        }

      }

      @media(max-width: layout.$MOBILE-PORTRAIT-MAX-WIDTH) {
        .mat-mdc-dialog-actions {
          margin-bottom: unset;

          .mat-mdc-button-base+.mat-button-base,
          .mat-mdc-button-base+.mat-mdc-button-base {
            margin-left: 0;
            margin-bottom: 20px;
          }
        }
      }

      @media(max-width: layout.$TABLET-PORTRAIT-MAX-WIDTH) {
        padding: 24px;
        width: 400px;
      }

    }
  }
}
