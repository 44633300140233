@use './fonts' as fonts;
@use './colors' as colors;

$HEADER-HEIGHT: 75px;
$DESKTOP-MIN-WIDTH: 1113px;
$TABLET-LANDSCAPE-MAX-WIDTH: 1112px;
$TABLET-LANDSCAPE-MIN-WIDTH: 835px;
$TABLET-PORTRAIT-MAX-WIDTH: 834px;
$TABLET-PORTRAIT-MIN-WIDTH: 768px;
$MOBILE-PORTRAIT-MAX-WIDTH: 676px;
$STEPPER-HEIGHT: 50px;

$flex-gap: 16px;

/*
side-panel and bottom-container styles for parcel-select,
locate-property and eligibility-check components
*/


.ckeditor-h1 {
  color: colors.$medBlackColor !important;
  font-family: fonts.$headerFont !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 24px !important;
  letter-spacing: 1.5px !important;
  line-height: 43px !important;
}

.ckeditor-h2 {
  color: colors.$accent-color-default !important;
  font-family: fonts.$bodyFont !important;
  font-style: normal !important;
  letter-spacing: 1.5px !important;
  line-height: 20px !important;
  font-size: 16px !important;
}

.ckeditor-div, .ck-content {
  color: colors.$accent-color-default !important;
  font-family: fonts.$bodyFont !important;
  font-style: normal !important;
  font-size: 14px !important;
  letter-spacing: 1.5px !important;
  line-height: 20px !important;
}

.legal-disclaimer {
  font-size: 10px !important;
  font-style: italic !important;
}

.side-panel {
  background: colors.$whiteColor;
  height: 100%;
  overflow-y: auto;
  width: 522px;
}

.bottom-container {
  background: colors.$whiteColor;
  bottom: 0;
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.15);
  padding: 16px 32px;
  position: fixed;
  width: 522px;

  > .button-container {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .link-container {
      align-items: center;
      display: flex;
      margin-left: 50px;

      > img {
        margin-right: 7px;
      }
    }
  }

  mat-divider {
    margin: 16px 0;
    position: relative;
  }

  .fc-link {
    align-items: center;
    display: flex;
    justify-content: center;

    fa-icon {
      margin-right: 5px;
    }
  }
}

@media (max-width: $TABLET-LANDSCAPE-MAX-WIDTH) {
  .side-panel {
    width: 394px;
  }

  .bottom-container {
    width: 394px;
  }
}

@media (max-width: 884px) {
  .side-panel {
    width: 334px;
  }

  .bottom-container {
    width: 334px;

    > .button-container {
      .link-container {
        margin-left: 0;
      }
    }
  }
}

fc-new-site-step-one {
  max-width: 100%;
  width: 540px;
}

.currency-mask-field {
  input.mat-mdc-input-element {
    bottom: 3px;
    position: relative;
    text-indent: 8px;
  }

  .currency-icon {
    bottom: 30px;
    color: colors.$medGrayColor;
    left: 8px;
    position: relative;
    width: 8px;
  }
}

// Dialogs
.default-dialog-wrapper {
  width: 625px;

  .header {
    color: colors.$darkGrayColor;
    font-family: fonts.$headerFont;
    font-size: 34px;
    line-height: 37px;
    margin-bottom: 36px;
    text-align: center;
  }

  .close-wrapper {
    display: flex;
    height: 20px;
    justify-content: flex-end;

    .close-icon {
      color: colors.$darkGrayColor;
      font-size: 18px;
    }
  }

  .form-field {
    margin-bottom: 25px;
    width: 100%;
  }

  .buttons-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .mat-mdc-radio-group .mat-mdc-radio-button {
    margin-bottom: 25px;
    margin-right: 50px;
  }
}

.align-center {
  text-align: center;
}
