@use "../colors" as colors;

@mixin radio-button-theme($theme)
{
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  fieldset {

    &:disabled {
      .mat-mdc-radio-button .mdc-form-field {
        color: colors.$lightGrayColor;
        pointer-events: none;
      }
    }
  }

}
