@use "../colors" as colors;
@use "../fonts" as fonts;


@mixin checkbox-theme($theme)
{
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  .mat-mdc-checkbox .mdc-form-field {
  --mdc-checkbox-state-layer-size: 20px;
    align-items: flex-start;

  }

}
