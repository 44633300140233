@use '../colors' as colors;
@use '../fonts' as fonts;
@use '../theme.scss' as theme;

fa-icon {
  // Colors
  &.black {
    color: colors.$blackColor;
  }

  &.blue {
    color: colors.$blueColor;
  }

  &.dark-gray {
    color: colors.$darkGrayColor;
  }

  &.gray {
    color: colors.$medGrayColor;
  }

  &.green {
    color: colors.$primary-color-default;
  }

  &.light-green {
    color: colors.$lightGreenColor;
  }

  &.med-light-gray {
    color: colors.$medLightGrayColor;
  }

  &.orange {
    color: colors.$orangeColor;
  }

  &.red {
    color: colors.$warn-color-default;
  }

  &.white {
    color: colors.$whiteColor !important;
  }

  &.yellow {
    color: colors.$yellowColor;
  }

  &.icon-button {
    color: colors.$lightGreenColor;
    background-color: colors.$primary-color-default;
    border-radius: 25%;
    font-size: 16px;
    padding: 3px;
  }

  // Font size mixin
  @mixin size($size) {
    &.size-#{$size} {
      font-size: #{$size}#{'px'};
    }
  }

  @include size(12);
  @include size(16);
  @include size(18);
  @include size(20);
  @include size(23);
}

// This gets FontAwesome icons to play nice within a MatIcon
.mat-icon {
  fa-icon {
    display: flex;
  }
}
