@use './theme.scss' as theme;

// easy references to the default, lighter, and darker versions
$whiteColor: #FFFFFF;
$primary-color-default: map-get(theme.$primary, 700);
$primary-color-lighter: map-get(theme.$primary, 400);
$primary-color-darker:  map-get(theme.$primary, 900);
$primary-contrast-color-default: $whiteColor;
$accent-color-default: map-get(theme.$accent, 700);
$accent-color-lighter: map-get(theme.$accent, 400);
$accent-color-darker:  map-get(theme.$accent, 900);
$accent-contrast-color-default: $whiteColor;
$warn-color-default: map-get(theme.$warn, 500);
$warn-color-lighter: map-get(theme.$warn, 300);
$warn-color-darker: map-get(theme.$warn, 700);
$warn-contrast-color-default: $whiteColor;


// Green
$lightGreenColor: #ABF18A;

// Blue
$blueColor: #0381EB;

// Red
$redOrangeColor: #E14A35;
$orangeColor: #FD852F;
$yellowColor: #F4EC22;

// Gray scale
$offWhiteColor: #FFFEFE;
$darkOffWhiteColor: #F8F8FA;
$lighterGrayColor: #F3F3F3;
$lightGrayColor: #D6D4D4;
$darkerLightGrayColor: #E0E0E0;
$darkLightGrayColor: #E5E5E5;
$medLightGrayColor: #C6C6C6;
$medGrayColor: #9BA4AA;
$medDarkGrayColor: #888888;
$darkGrayColor: #424242;
$lightBlack: #151516;
$medBlackColor: #201F1F;
$blackColor: #000000;

// Extra Promo/Home Page Colors

$pacificBlue: #0199CC;

