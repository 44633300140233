@use "./colors" as colors;
@use "./fonts" as fonts;

h2 {
  color: colors.$medDarkGrayColor;
  font-family: fonts.$headerFont;
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 1px;
  line-height: 36px;
  text-transform: uppercase;
}

h3 {
  color: colors.$whiteColor;
  font-family: fonts.$headerFont;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  margin-top: 75px;

  letter-spacing: 1px;
  text-transform: uppercase;
}

.instruction-container {
  margin: 30px 0;
}

.step-number {
  color: colors.$primary-color-default;
  font-family: fonts.$headerFont;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  letter-spacing: 1.5px;
  line-height: 34px;
}

.instruction-title {
  color: colors.$accent-color-default;
  font-family: fonts.$bodyFont;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1.5px;
}

.instruction-body {
  color: colors.$accent-color-default;
  font-family: fonts.$bodyFont;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.5px;
}


.form-title {
  .title-text {
    color: colors.$whiteColor;
    font-family: fonts.$headerFont;
    font-size: 38px;
    line-height: 56px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

/* TODO: Temp commented this out because it overrode not boxed material inputs.  mat-select's aren't showing red border now. */
//.ng-touched.ng-invalid:not(form):not(mat-form-field) {
//  border: 2px solid colors.$warn-color-darker !important;
//}

.validation-error {
  color: colors.$warn-color-darker;
  font-family: fonts.$bodyFont;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.005em;
}

.validation-error-on-blue {
  margin-top: 3px;
  color: colors.$warn-color-darker;
  font-family: fonts.$bodyFont;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.005em;
}
