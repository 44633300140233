@use '../layout' as layout;

// Text-aligns to specified position
@mixin align($side) {
  .align-#{$side} {
    text-align: #{$side};
  }
}

@include align('left');
@include align('center');
@include align('right');

@mixin width(
  $width: '100',
  $numElements: 1,
) {
  @if $width == '33' {
    .w-#{$width}-#{$numElements} {
      width: calc(33.33% - ((#{layout.$flex-gap} * (#{$numElements} - 1)) / #{$numElements}));
    }
  } @else if $width == '66' {
    .w-#{$width}-#{$numElements} {
      width: calc(66.66% - ((#{layout.$flex-gap} * (#{$numElements} - 1)) / #{$numElements}));
    }
  } @else {
    .w-#{$width}-#{$numElements} {
      width: calc( percentage(calc($width / 100)) - ((#{layout.$flex-gap} * (#{$numElements} - 1)) / #{$numElements}));
    }
  }
}

@include width(100, 1);
@include width(90, 2);
@include width(80, 2);
@include width(75, 2);
@include width(70, 2);
@include width(66, 2);
@include width(60, 2);
@include width(60, 3);
@include width(55, 3);
@include width(50, 2);
@include width(50, 3);
@include width(48, 3);
@include width(47, 3);
@include width(45, 3);
@include width(44, 3);
@include width(40, 4);
@include width(40, 3);
@include width(40, 2);
@include width(35, 2);
@include width(35, 3);
@include width(35, 4);
@include width(34, 2);
@include width(33, 3);
@include width(33, 2);
@include width(32, 2);
@include width(31, 6);
@include width(31, 2);
@include width(30, 2);
@include width(30, 3);
@include width(30, 4);
@include width(28, 3);
@include width(25, 2);
@include width(25, 3);
@include width(25, 4);
@include width(20, 5);
@include width(20, 4);
@include width(20, 3);
@include width(20, 2);
@include width(15, 6);
@include width(15, 4);
@include width(10, 2);
@include width(10, 3);
@include width(10, 4);
@include width(6, 3);
@include width(5, 2);
@include width(5, 3);
@include width(5, 4);
@include width(4, 6);
@include width(4, 3);

