@use "../colors" as colors;
@use "../fonts" as fonts;
@use "../layout" as layout;

@mixin page-header-theme($theme)
{
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  header.page-header {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 15px 43px 0px 43px;
    top: calc(#{layout.$HEADER-HEIGHT} + #{layout.$STEPPER-HEIGHT});
    width: 100%;
    background-color: colors.$darkLightGrayColor;

    .header-text {
      font-family: fonts.$headerFont;
      font-weight: normal;
      font-size: 24px;
      line-height: 36px;
      color: colors.$darkGrayColor;
    }

    .subheader {
      font-family: fonts.$bodyFont;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 1.5px;
      color: colors.$medBlackColor;
    }

    mat-divider {
      margin: 0;
    }

    @media(max-width: layout.$TABLET-LANDSCAPE-MAX-WIDTH) {
      padding: 16px 16px 0px 16px;
    }
  }


  .buyer-header-bar {
    width: 876px;
    height: 93px;

    background: colors.$whiteColor;
    box-shadow: 0 4px 4px rgba(163, 163, 163, 0.25);
    padding: 15px 30px;
    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-top: 20px;

    img {
      max-width: 400px;
    }

    .total-annual-value-container {
      font-family: fonts.$bodyFont;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.1px;
      color: colors.$medDarkGrayColor;

      div {
        font-family: fonts.$headerFont, sans-serif;
        font-weight: 500;
        font-size: 36px;
        line-height: 115.5%;
        letter-spacing: 1px;
        color: colors.$primary-color-default;
        text-align: right;
      }
    }

    @media (max-width: layout.$TABLET-PORTRAIT-MAX-WIDTH) {
      width: unset;
      margin-top: 30px;
    }
  }
}
