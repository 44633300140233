@use "../colors.scss" as colors;
@use "../fonts.scss" as fonts;
@use "../layout.scss" as layout;

@mixin select-theme($theme)
{
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  input:focus,
  select:focus,
  textarea:focus,
  button:focus,
  mat-select:focus {
    outline: none;
  }

  div.mat-select-trigger > div.mat-select-arrow-wrapper {
    transform: unset;
  }

  .frequency-select {
    border: 5px;
  }

  // used to increase the width of select droplists
  @media (min-width: layout.$MOBILE-PORTRAIT-MAX-WIDTH) {
    .mat-mdc-option .mdc-list-item__primary-text {
      white-space: nowrap;
    }

    .cdk-overlay-pane {
      min-width: fit-content;
    }
  }
}
