@use "../theme.scss" as theme;
@use "../colors";
@use "../fonts";

@mixin button-theme($theme)
{
  button.mat-mdc-button,
  button.mat-mdc-raised-button,
  button.mat-mdc-outlined-button,
  button.mat-mdc-unelevated-button {
    min-width: 164px;
    font-weight: 400;
    height: 36px;



    &.large {
      min-width: 212px;
    }

    .mdc-button__label {
      z-index: 0;
    }

  }

  button.mat-mdc-raised-button, button.mat-mdc-unelevated-button {

    &:not(:disabled) {
      color: colors.$whiteColor
    }
  }

  %link {
    background: none;
    text-decoration: none;
    font-size: 14px;
    font-family: fonts.$bodyFont, "Helvetica Neue", sans-serif;
  }

  // styling for all blue links
  .fc-link {
    @extend %link;
    color: colors.$blueColor;

  }

  .fc-green-link {
    @extend %link;
    color: colors.$primary-color-default;

    &:disabled, &.disabled {
      pointer-events: none;
      cursor: not-allowed;
      color: map-get(theme.$accent, 100) !important;
    }
  }

  // styling for gray back links with arrow image
  .fc-back-link {
    width: fit-content;
    text-transform: uppercase;
    color: map-get(theme.$accent, 200);

    fa-icon {
      margin-right: 11px;
    }
  }


  // hover and disabled styling for links
  .fc-link, .fc-back-link {
    font-weight: 400;

    &:hover {
      text-decoration: underline;
    }

    &.disabled {
      pointer-events: none;
      color: map-get(theme.$accent, 100);
    }
  }

  // for primary actions
  .mat-mdc-raised-button {
    &:hover {
        background-color: colors.$primary-color-lighter !important;
    }

    &:disabled {
      //TODO: Figure out how to override this on a theme level
      background-color: map-get(theme.$accent, 100) !important;
    }
  }

  // for secondary actions
  .mat-mdc-outlined-button {
    &.mat-primary {

      &:not(:disabled) {
        border-color: colors.$primary-color-default;
        color: colors.$primary-color-default;
      }
    }

    &:disabled {
      border-color: map-get(theme.$accent, 100);
    }
  }

  .map-view-button button {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 500;
    height: 100%;
    justify-content: center;
    letter-spacing: 1.25px;
    line-height: 16px;
    text-transform: uppercase;
  }

}
