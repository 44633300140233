@use '../colors' as colors;
@use '../layout' as layout;

@mixin toast-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .toast--container {
    z-index: 99999999;
    position: fixed;
    display: flex;
    align-items: center;
    flex-direction: column;
    bottom: 10px;
    width: 100%;
    row-gap: 10px;
  }

  .toast--body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 655px;
    padding: 12px 24px;
    box-shadow: 0 3px 5px 0 #00000033;
    letter-spacing: 0.25px;

    .message--info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      flex-grow: 1;
      margin: auto;
    }

    @media (max-width: layout.$MOBILE-PORTRAIT-MAX-WIDTH) {
      width: 95%;
    }
  }

  .button--dismiss {
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1.25px;
    margin-left: 20px;
  }

  .toast--info {
    background-color: colors.$blueColor;
    color: colors.$primary-contrast-color-default;
    border: 0;
  }

  .toast--warn {
    background-color: colors.$warn-color-default;
    color: colors.$warn-contrast-color-default;
    border: 0;
  }

  .toast--success {
    background-color: colors.$primary-color-default;
    color: colors.$primary-contrast-color-default;
    border: 0;
  }
}
