@use "../colors" as colors;
@use "../fonts" as fonts;
@use "../layout" as layout;

@mixin form-field-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  .mat-mdc-form-field {
    min-width: 200px;

    &.full-width {
      width: 100%;
    }

    .mat-mdc-text-field-wrapper {
      background-color: colors.$whiteColor;
      margin: .25em 0;
      flex: unset;
    }

  }

  // mimic form-field styling on mapbox inputs
  .forward-location-container.form, .reverse-location-container.form {

    svg {
      top: 12px
    }

    input {
      border: 1px solid colors.$medGrayColor;
      border-radius: 4px;
      height: 42.8px;
      background-color: colors.$whiteColor;

      &:hover, &:focus {
        border-width: 2px;
        padding: 5px 34px;
      }

      &:focus {
        border-color: colors.$primary-color-default;
      }
    }
  }

  .fc-datepicker {
    .mat-mdc-text-field-wrapper {
      // based on form field density set in styles.scss
      height: 44px;
    }

    &.upload-region-datepicker {
      width: 414px;
    }
  }

  .mat-mdc-input-element.hidden {
    display: none !important;
  }
}
